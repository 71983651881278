.project-modal .modal-dialog {
  margin: 1rem;
  max-width: 100vw;
}

.project-modal .modal-content {
  padding: 100px 0;
  text-align: center;
}

.project-modal .modal-content h2 {
  font-size: 3em;
  margin-bottom: 15px;
}

.project-modal .modal-content p {
  margin-bottom: 30px;
}

.project-modal .modal-content p.item-intro {
  font-size: 16px;
  font-style: italic;
  margin: 20px 0 30px;
  font-family: "Droid Serif", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.project-modal .modal-content ul.list-inline {
  margin-top: 0;
  margin-bottom: 30px;
}

.project-modal .modal-content img {
  margin-bottom: 30px;
}

.project-modal .modal-content button {
  cursor: pointer;
}

.project-modal .close-modal {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 75px;
  height: 75px;
  cursor: pointer;
  background-color: transparent;
}

.project-modal .close-modal:hover {
  opacity: 0.3;
}

.project-modal .close-modal .lr {
  /* Safari and Chrome */
  z-index: 1051;
  width: 1px;
  height: 75px;
  margin-left: 35px;
  /* IE 9 */
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  background-color: #212529;
}

.project-modal .close-modal .lr .rl {
  /* Safari and Chrome */
  z-index: 1052;
  width: 1px;
  height: 75px;
  /* IE 9 */
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background-color: #212529;
}
