.project-item {
  right: 0;
  margin: 0 0 15px;
}

.project-item .project-link {
  position: relative;
  display: block;
  max-width: 400px;
  margin: 0 auto;
  cursor: pointer;
}

.project-item .project-link .project-hover {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  opacity: 0;
  background: rgba(251, 129, 34, 0.9);
}

.project-item .project-link .project-hover:hover {
  opacity: 1;
}

.project-item .project-link .project-hover .project-hover-content {
  font-size: 40px;
  position: absolute;
  top: 50%;
  width: 100%;
  height: 20px;
  margin-top: -9%;
  text-align: center;
  color: white;
}

.project-item .project-caption {
  max-width: 400px;
  margin: 0 auto;
  padding: 25px;
  text-align: center;
  background-color: #fff;
}

.project-item .project-caption h4 {
  margin: 0;
  text-transform: none;
}

.project-item .project-caption p {
  font-size: 16px;
  font-style: italic;
  margin: 0;
  font-family: "Droid Serif", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

@media (min-width: 768px) {
  .project-item {
    margin: 0 0 30px;
  }
}
