.about-me {
  margin-bottom: 50px;
  text-align: center;
}

.about-me img {
  width: 225px;
  height: 225px;
  border: 7px solid rgba(0, 0, 0, 0.1);
}

.about-me h4 {
  margin-top: 25px;
  margin-bottom: 0;
  text-transform: none;
}

.about-me p {
  margin-top: 0;
}

.dev-icons {
  font-size: 3rem;
}

.dev-icons .list-inline-item:hover {
  color: #fb8122;
}
