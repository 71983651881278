#navMain {
  background-color: #1d2228;
}

#navMain .navbar-brand {
  color: #fb8122;
  font-family: "Kaushan Script", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

#navMain .navbar-brand:active,
#navMain .navbar-brand:focus,
#navMain .navbar-brand:hover {
  color: #fb8122;
}

#navMain .navbar-nav .nav-item .nav-link {
  font-size: 90%;
  font-weight: 400;
  padding: 0.75em 0;
  letter-spacing: 1px;
  color: white;
  text-transform: uppercase;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

#navMain .navbar-nav .nav-item .nav-link.active,
#navMain .navbar-nav .nav-item .nav-link:hover {
  color: #fb8122;
}

.phone {
  margin-left: 1.5%;
}

.linkedin {
  margin-left: 1.5%;
  margin-bottom: 0.3%;
}

.github {
  margin-left: 1.5%;
  margin-bottom: 0.3%;
}

.navbar-phone {
  color: white;
}

.navbar-email {
  color: white;
}

.navbar-linkedin {
  color: white;
  margin-bottom: 0.3%;
  margin-left: 1.5%;
  font-size: 1.3rem;
}

.navbar-github {
  color: white;
  margin-bottom: 0.3%;
  margin-left: 1.5%;
  font-size: 1.3rem;
}

.nav-contact:hover {
  color: #fb8122;
  text-decoration: none;
}

@media (min-width: 768px) {
  #navMain .navbar-nav .nav-item .nav-link {
    padding: 1.1em 1em !important;
  }
}

@media (max-width: 768px) {
  .email {
    margin-bottom: 2%;
  }

  .phone {
    margin-bottom: 2%;
  }

  .linkedin {
    margin-bottom: 2%;
  }

  .github {
    margin-bottom: 2%;
  }
}
