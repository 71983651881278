.footer {
  padding: 15px 0;
  text-align: center;
  background-color: #1d2228;
  color: white;
}

.footer span.copyright {
  font-size: 90%;
  line-height: 40px;
  text-transform: none;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
